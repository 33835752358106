<script>
  import { getContext } from "svelte";
  import { stocks } from "../stores.js";
  export let feature;
  export let proj;

  const { data, width, height } = getContext("LayerCake");
  $: projectionFn = proj.fitSize([$width, $height], $data);

  $: open = $stocks[feature.properties.SHORT_NAME_DISPLAY].open;
  const strarr = 214;
  let stroff = 0;

  $: location = projectionFn([
    feature.properties.LONGITUDE,
    feature.properties.LATITUDE,
  ]);
  $: position = projectionFn(feature.geometry.coordinates);

  let innerWidth;

  let radius = 34;

  $: if (innerWidth > 1200) {
    radius = 34;
  } else if (innerWidth > 1024) {
    radius = 30;
  } else if (innerWidth > 900) {
    radius = 28;
  } else {
    radius = 25;
  }
</script>

<svelte:window bind:innerWidth />{#if position}
  <circle class="loc" class:opensm="{open=== true}" cx={location[0]} cy={location[1]} r="4" />
  <line x1={location[0]} y1={location[1]} x2={position[0]} y2={position[1]} />
  <circle
    class="circle"
    class:open
    cx={position[0]}
    cy={position[1]}
    r={radius}
    style="stroke-dasharray: {strarr}; stroke-dashoffset: {stroff};"
  />
  <circle
    class="inner-circle"
    class:open
    cx={position[0]}
    cy={position[1]}
    r={radius}
  />
{/if}

<style>
  .circle,
  .inner-circle,
  line {
    display: none;
  }

  .loc {
    stroke-width: 0;
    fill: #ff6666;
    transform-origin: center;
    transform-box: fill-box;
  }

  .opensm {
    fill: #0e976e;
  }

  .open {
    fill: #0e976e;
  }

  @media only screen and (min-width: 768px) {
    .loc {
      fill: #fff;
    }
    .circle {
      display: block;
      stroke-width: 6px;
      transition: stroke-dashoffset 1s linear;
      stroke: #ffffff;
      transform-origin: center;
      transform-box: fill-box;
      fill: #ff6666;
      transform: rotate(-90deg);
    }

    .inner-circle {
      display: block;
      transform-origin: center;
      transform-box: fill-box;
      fill: #f17b7b;
    }

    .open {
    fill: #0e976e;
  }

    line {
      display: block;
      stroke-width: 2px;
      stroke: rgba(255, 255, 255, 0.705);
    }
  }

  @media only screen and (min-width: 1200px) {
    .circle {
      display: block;
      stroke-width: 7px;
      transition: stroke-dashoffset 1s linear;
      stroke: #ffffff;
      transform-origin: center;
      transform-box: fill-box;
      fill: #ff6666;
      transform: rotate(-90deg);
    }

    .inner-circle {
      display: block;
      transform-origin: center;
      transform-box: fill-box;
      fill: #f17b7b;
    }

    .open {
    fill: #0e976e;
  }

    line {
      display: block;
      stroke-width: 2px;
      stroke: rgba(255, 255, 255, 0.705);
    }
  }


</style>
