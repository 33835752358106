<script>
  import { getContext } from "svelte";
  import { geoPath } from "d3-geo";
  export let proj;

  const { data, width, height } = getContext("LayerCake");
  $: features = $data.features;
  $: projectionFn = proj.fitSize([$width, $height], $data);
  $: geoPathFn = geoPath(projectionFn);

</script>

<style>
  .feature-path {
    stroke: rgb(177, 177, 177);
    stroke-width: 0px;
    fill: #919bbb;
  }
</style>

<defs>
  <linearGradient id="MapGradient">
    <stop offset="5%" stop-color="#010024" />
    <stop offset="95%" stop-color="#0072c6" />
  </linearGradient>
</defs>

<g class="map">
  {#each features as feature}
    <path class="feature-path" d={geoPathFn(feature)} />
  {/each}
</g>