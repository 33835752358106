<script>
  export let stock;
  export let city;
  import { stocks } from "../stores.js";

  $: open = $stocks[stock].open;
  $: remh = $stocks[stock].time.h;
  $: remm = $stocks[stock].time.m -1;
</script>

<div class="stock" class:open={open}>
  <span>{stock}</span>
  <span class="city">{city}</span>
<div class="timer">
  {remh}{#if remh > 0}<span>h</span>{/if} {remm}<span>m</span>
</div>
</div>

<style>
.stock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ff6666;
    border-radius: 3px;
    margin: .3em 0;
    width: 100%;
    max-width: 80px;
    height: 40px;
    font-size: 0.6em;
    line-height: 100%;
    padding-top: 2px;
  }

  .open {
    background-color: #0e976e;
  }

  .timer {
    font-size: 11px;
    font-weight: 600;
    line-height: 120%;
    margin-top: 2px;
  }
</style>