<script>
  import CounterMobile from "./CounterMobile.svelte"
  import { stocks } from "../stores.js";
</script>

<style>
  .grid {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 3px;
    padding: 0 0.5em;
    width: 100%;
    max-width: 520px;
    margin-bottom: .6em;
    overflow: hidden;
    position: relative;
  }
  .stlist {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
  }

  .title {
    margin-bottom: .3em;
    font-size: .6em;
    height: 35px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    justify-content: center;
  }

  .legend {
    display:flex;
    position: absolute;
    left: 10px;
    bottom: 5px;
  }

  .legend .stock {
    padding: 0 .6em;
    margin-right: .6em;
    color: #fff;
  }

  .stock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ff6666;
    border-radius: 3px;
    margin: .3em 0;
    width: 100%;
    max-width: 80px;
    height: 16px;
    font-size: 0.6em;
  }

  .open {
    background-color: #0e976e;
  }

  @media only screen and (min-width: 768px) {
    .grid {
      display: none;
    }
  }

</style>
{#if Object.keys($stocks).length > 0}
<div class="grid">
  <div class="stlist">
    <div class="title">North America</div>
    <CounterMobile stock="TSX" city="Toronto"/>
    <CounterMobile stock="NYSE" city="New York"/>
    <CounterMobile stock="NASDAQ" city="New York"/>
    <CounterMobile stock="BMV" city="Mexico C."/>
  </div>
  <div class="stlist">
    <div class="title">South America</div>
    <CounterMobile stock="B3" city="São Paulo"/>
  </div> 
  <div class="stlist"> 
    <div class="title">Europe</div>
    <CounterMobile stock="MOEX" city="Moscow"/>
    <CounterMobile stock="LSE" city="London"/>
    <CounterMobile stock="FSX" city="Frankfurt"/>
    <CounterMobile stock="EURONX" city="Paris"/>
    <CounterMobile stock="SIX" city="Zürich"/>
    <CounterMobile stock="BME" city="Madrid"/>
  </div>
  <div class="stlist">
    <div class="title">Africa</div>
    <CounterMobile stock="JSE" city="Johannesb."/>
  </div>
  <div class="stlist">
    <div class="title">Asia</div>
    <CounterMobile stock="KRX" city="Seoul"/>
    <CounterMobile stock="JPX" city="Tokyo"/>
    <CounterMobile stock="SSE" city="Shanghai"/>
    <CounterMobile stock="TWSE" city="Taipei"/>
    <CounterMobile stock="SZSE" city="Shenzhen"/>
    <CounterMobile stock="HKEX" city="Hong Kong"/>
    <CounterMobile stock="SET" city="Bangkok"/>
    <CounterMobile stock="SGX" city="Singapore"/>
    <CounterMobile stock="IDX" city="Jakarta"/>
  </div>
  <div class="stlist">
    <div class="title">Australia</div>
    <CounterMobile stock="ASX" city="Sidney"/>
  </div>
  <div class="legend">
    <div class="stock open">open</div>
    <div class="stock">closed</div>
  </div>
</div>
{/if}