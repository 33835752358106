import { writable, readable, derived, get } from "svelte/store";
import { DateTime, Interval } from "luxon";

export const data = writable({ features: [] });

export async function initStocksStore() {
  const res = await fetch(`/stocks-151120.geojson`);
  let st = await res.json();
  data.set(st);
}

export const time = readable(new Date(), function start(set) {
  const d = new Date();
  const interval = setInterval(() => {
    set(new Date());
  }, (60 - d.getMinutes()) * 1000);

  return function stop() {
    clearInterval(interval);
  };
});


const stocksd = derived(data,($data, set) => {
  let map = {};
  $data.features.forEach((feature) => {
    const p = feature.properties;
    const timeZone = p.IANA_TIMEZONE;
    let popen = p.OPEN_LOCAL.split(":");
    let pclose = p.CLOSE_LOCAL.split(":");
    let holidays = p.HOLIDAYS_2020.split(",");
    let dtHolidays = holidays.map((e) => {
      return e.trim().length == 10
      ? DateTime.fromFormat(e.trim(), "dd.LL.y", { zone: timeZone })
      : DateTime.fromFormat(e.trim(), "dd.LL.y-hh:mm", { zone: timeZone });
    });
    map[p.SHORT_NAME_DISPLAY] = {dtHolidays: dtHolidays, popen: popen, pclose:pclose}
  });
  
  set(map)
},{});

export const stocks = derived(
  [time, data, stocksd],
  ([$time, $data, $stocksd], set) => {
    let map = {};
    if (Object.keys($stocksd).length === 0) {
      set(map)
      return
    }
    $data.features.forEach((feature) => {
      const p = feature.properties;
      const timeZone = p.IANA_TIMEZONE;

      const popen = $stocksd[p.SHORT_NAME_DISPLAY].popen
      const pclose = $stocksd[p.SHORT_NAME_DISPLAY].pclose
      const dtHolidays = $stocksd[p.SHORT_NAME_DISPLAY].dtHolidays

      let currentDate = DateTime.fromJSDate($time).setZone(timeZone);
      let openDate = currentDate.set({
        hour: popen[0],
        minute: popen[1],
        second: 0,
        millisecond: 0,
      });
      let closeDate = currentDate.set({
        hour: pclose[0],
        minute: pclose[1],
        second: 0,
        millisecond: 0,
      });

      let holiday = false;
      for (let i = 0; i < dtHolidays.length; i++) {
        const dt = dtHolidays[i];
        if (
          currentDate.hasSame(dt, "year") &&
          currentDate.hasSame(dt, "month") &&
          currentDate.hasSame(dt, "day")
        ) {
          console.log(dt.toString());
          if (dt.hour > 0) {
            closeDate = dt;
            break;
          }
          holiday = true;
        }
      }

      let i = Interval.fromDateTimes(openDate, closeDate);
      // Checks if the stock is open based on opening hours and weekend
      const wd = currentDate.weekday;
      // Remaining Time calculation for weekdays
      switch (wd) {
        case 5:
          openDate = openDate.plus({ days: 3 }); // We add 3 days on Friday
          break;
        case 6:
          openDate = openDate.plus({ days: 2 }); // We add 2 days on Saturday
          break;
        case 7:
          openDate = openDate.plus({ days: 1 }); // We 1 day on Sunday
          break;
        default:
          if (currentDate > openDate) openDate = openDate.plus({ days: 1 }); // We add 1 day if the stock is open
          break;
      }

      let open =
        i.contains(currentDate) && !holiday && wd != 6 && wd != 7
          ? true
          : false;

      let diff = open
        ? closeDate.diff(currentDate, ["hours", "minutes"])
        : openDate.diff(currentDate, ["hours", "minutes"]);

      // diff.toFormat("h'h'm'm'")
      let remh = diff.hours > 0 ? diff.hours : "";
      let remm = Math.ceil(diff.minutes);

      map[p.SHORT_NAME_DISPLAY] = { open: open, time: { h: remh, m: remm } };
    });
    set(map);
  },
  {}
);
