<script>
	import { onMount } from "svelte";
  import * as topojson from "topojson-client";
  import { LayerCake, Svg, Html } from "layercake";
  import Map from "./Map.svelte";
  import world from "./landt.js";
  import { initStocksStore, data } from "./stores.js";
  import Counter from "./components/Counter.svelte";
  import { geoNaturalEarth1 } from "d3-geo";
  import CounterHtml from "./components/CounterHtml.svelte";
  import Legend from "./components/Legend.svelte";
  import StockListMobile from "./components/StockListMobile.svelte"
  // import CookieBanner from "./components/CookieBanner.svelte"
  let geojson = topojson.feature(world, world.objects.Landv1);
	let projection = geoNaturalEarth1();
	
	onMount(async () => {
		initStocksStore();
  });
</script>

<!-- <CookieBanner /> -->

<div class="map-container">
	<LayerCake data={geojson}>
		<Svg>
			<Map proj={projection} />
		</Svg>

		<Svg>
			{#each $data.features as feature}
				<Counter {feature} proj={projection} />
			{/each}
		</Svg>

		<Html>
			{#each $data.features as feature}
				<CounterHtml {feature} proj={projection} />
			{/each}
		</Html>

		<Html>
			<Legend />
		</Html>
	</LayerCake>
</div>

<StockListMobile />

<style>
  .map-container {
    width: 100%;
    height: 230px;
    padding: 0 0.6em;
    margin-top: 0.6em;
    box-sizing: border-box;
    overflow: hidden;
  }

  .listmobile {
    overflow: hidden;
  }

  @media only screen and (min-width: 560px) {
    .map-container {
      width: 100%;
      height: 320px;
      padding: 1em 2em;
      margin-top: 0em;
    }
  }

	@media only screen and (min-width: 768px) {
    .map-container {
      width: 100%;
      height: 100%;
      padding: 1em;
      min-height: 60%;
      margin-top: 0em;
    }
  }

  @media only screen and (min-width: 1024px) {
    .map-container {
      height: 100%;
      min-height: 80%;
    }
  }

</style>