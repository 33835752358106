<script>
  import { getContext, onMount } from "svelte";
  import { stocks } from "../stores.js";
  export let feature;
  export let proj;
  const { data, width, height } = getContext("LayerCake");
  $: projectionFn = proj.fitSize([$width, $height], $data);

  const sn = feature.properties.SHORT_NAME_DISPLAY;
  const ct = feature.properties.MARKET_PLACE_DISPLAY;

  $: position = projectionFn(feature.geometry.coordinates);
  $: remh = $stocks[feature.properties.SHORT_NAME_DISPLAY].time.h;
  $: remm = $stocks[feature.properties.SHORT_NAME_DISPLAY].time.m -1;
</script>

<style>
    .stock, .timer, .city {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    .stock {
    position: absolute;
    text-align: center;
    width: 100px;
    margin-left: -50px;
    margin-top: -20px;
    display: block;
    color: #1e1e1e;
    font-size: 7px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    /* font-weight: 900; */
    letter-spacing: 0.1em;
  }

  .timer {
    position: absolute;
    text-align: center;
    display: block;
    font-size: 10px;
    /* font-weight: 900; */
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: #fff;
    width: 80px;
    height: 20px;
    margin-left: -40px;
    margin-top: -8px;
    word-spacing: -.1em;
  }

  .timer span {
    font-size: 0.7em;
  }

  .city {
    position: absolute;
    text-align: center;
    display: block;
    font-size: 7px;
    color: #1e1e1e;
    width: 100px;
    height:20px;
    margin-top: 6px;
    margin-left: -50px;
    /* font-weight: 900; */
    font-family: "Montserrat", sans-serif;
  }
}

@media only screen and (min-width: 1200px) {
  .stock {
    margin-left: -50px;
    margin-top: -22px;
    font-size: 9px;
  }

  .timer {
    font-size: 14px;
    /* font-weight: 900; */
    width: 80px;
    height: 20px;
    margin-left: -40px;
    margin-top: -10px;
  }

  .timer span {
    font-size: 0.7em;
  }

  .city {
    font-size: 9px;
    margin-top: 10px;
    margin-left: -50px;
  }
}


</style>

{#if position}
  <div class="stock" style="left:{position[0]}px;top:{position[1]}px;">
    {sn}
  </div>
  <div class="timer" style="left:{position[0]}px;top:{position[1]}px;">
    {remh}{#if remh > 0}<span>h</span>{/if} {remm}<span>m</span>
  </div>
  <div class="city" style="left:{position[0]}px;top:{position[1]}px;">{ct}</div>
{/if}
